<template>
    <div class="gis_page">
        <div class="layout_top"></div>
        <div class="layout_left"></div>
        <div class="layout_right"></div>
        <div class="layout_bottom"></div>

        <gis-header></gis-header>

        <div class="left_tree">
            <div class="info_title">
                区域信息
                <div class="title_desc">Area Info</div>
            </div>
            <el-tree :data="treeData" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </div>

        <div class="right_info">
            <!-- 总览 -->
            <div v-if="!pageType">
                <div class="info_item">
                    <div class="info_title">
                        实有信息
                        <div class="title_desc">Actual data</div>
                    </div>
                    <div class="grid_box">
                        <div class="grid_item">
                            <div class="mgb-10">实有人口</div>
                            <div class="grid_num">6680</div>
                        </div>
                        <div class="grid_item">
                            <div class="mgb-10">实有建筑</div>
                            <div class="grid_num">426</div>
                        </div>
                    </div>
                    <div class="grid_box">
                        <div class="grid_item">
                            <div class="mgb-10">实有企业</div>
                            <div class="grid_num">215</div>
                        </div>
                        <div class="grid_item">
                            <div class="mgb-10">实有部件</div>
                            <div class="grid_num">232</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="info_title">
                        重点数据
                        <div class="title_desc">The key data</div>
                    </div>
                    <div class="grid_box">
                        <div class="grid_item">
                            <div class="mgb-10">重点项目</div>
                            <div class="grid_num">6</div>
                        </div>
                        <div class="grid_item">
                            <div class="mgb-10">重大活动</div>
                            <div class="grid_num">2</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 重点项目 -->
            <div v-if="pageType=='projects'">
                <div class="info_item">
                    <div class="flex-between mgb-20">
                        <div class="grid_label">项目总数</div>
                        <div class="grid_num">8</div>
                    </div>
                    <div class="flex-between mgb-20">
                        <div class="grid_label">项目金额</div>
                        <div class="grid_num">40.78万元</div>
                    </div>
                    <div class="flex-between">
                        <div>
                            <div class="ac mgb-10">已完成</div>
                            <el-progress type="circle" :width="90" :percentage="75" :color="'#54F8D3'" :format="(percentage)=>{return '6'}"></el-progress>
                        </div>
                        <div>
                            <div class="ac mgb-10">进行中</div>
                            <el-progress type="circle" :width="90" :percentage="25" :color="'#54F8D3'" :format="(percentage)=>{return '2'}"></el-progress>
                        </div>
                    </div>
                </div>
                <div class="mgt-20">
                    <div class="grid_label mgb-10">项目列表</div>
                    <ul class="project_list">
                        <li v-for="(item,index) in projectList" :key="index"><span>●</span>{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <!-- 指挥调度 -->
            <div v-if="pageType=='command'">
                <div class="info_item">
                    <div class="flex-between mgb-20">
                        <div class="grid_label">全部人口</div>
                        <div class="grid_num">40.78万元</div>
                    </div>
                    <div class="flex-start mgb-10">
                        <span>常住人口</span>
                        <span class="grid_num_small">2820</span>
                    </div>
                    <div class="flex-start mgb-10">
                        <span>户籍人口</span>
                        <span class="grid_num_small">2620</span>
                    </div>
                    <div class="flex-start mgb-10">
                        <span>流动人口</span>
                        <span class="grid_num_small">120</span>
                    </div>
                    <div class="flex-start mgb-10">
                        <span>外出人口</span>
                        <span class="grid_num_small">120</span>
                    </div>
                </div>
                <div class="info_item">
                    <div class="flex-between mgb-20">
                        <div class="grid_label">视频监控</div>
                        <div class="grid_num">48</div>
                    </div>
                </div>
                <div>
                    <div class="grid_label mgb-10">外来人员登记</div>
                    <ul class="project_list">
                        <li class="flex-between" v-for="(item,index) in personList" :key="index">
                            <span>●</span>{{item.name}}
                            <div>{{item.time}}</div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 文化旅游 -->
            <div v-if="pageType=='travel'">
                <div>
                    <div class="grid_label mgb-10">文化旅游活动</div>
                    <ul class="project_list">
                        <li v-for="(item,index) in travelProjList" :key="index">
                            <span>●</span>{{item.name}}
                            <div class="time">{{item.time}}</div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 生态振兴 -->
            <div v-if="pageType=='ecology'">
                <div>
                    <div class="grid_label mgb-10">生态振兴规划</div>
                    <ul class="project_list">
                        <li v-for="(item,index) in ecologyProjList" :key="index">
                            <span>●</span>{{item.name}}
                            <div class="time">{{item.time}}</div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 数字农业 -->
            <div v-if="pageType=='agriculture'">
                <div class="info_item">
                    <div class="flex-between mgb-20">
                        <div class="grid_label">总面积</div>
                        <div class="grid_num">202.25平方千米</div>
                    </div>
                    <div class="flex-between mgb-20">
                        <div>
                            <div class="ac mgb-10">耕地</div>
                            <el-progress class="small_text" type="circle" :width="90" :percentage="75" :color="'#54F8D3'" :format="(percentage)=>{return '11万亩'}"></el-progress>
                        </div>
                        <div>
                            <div class="ac mgb-10">林地</div>
                            <el-progress class="small_text" type="circle" :width="90" :percentage="25" :color="'#54F8D3'" :format="(percentage)=>{return '8.6万亩'}"></el-progress>
                        </div>
                    </div>
                    <div class="flex-between">
                        <div>
                            <div class="ac mgb-10">宅基地</div>
                            <el-progress class="small_text" type="circle" :width="90" :percentage="75" :color="'#54F8D3'" :format="(percentage)=>{return '12600'+'\n平方米'}"></el-progress>
                        </div>
                        <div>
                            <div class="ac mgb-10">工业用地</div>
                            <el-progress class="small_text" type="circle" :width="90" :percentage="25" :color="'#54F8D3'" :format="(percentage)=>{return '12600'+'\n平方米'}"></el-progress>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 底部menu -->
        <ul class="bottom_menu" v-if="!pageType">
            <li class="menu_item" v-for="(item,index) in menuList" :key="index" @click="switchMenu(index,item)">
                <img :src="item.imgUrl" alt="">
                <div class="menu_text" :class="[{'active':item.active},item.className]">{{item.name}}</div>
            </li>
        </ul>
        <ul class="bottom_menu" v-if="pageType=='command'">
            <li class="menu_item" v-for="(item,index) in menuCommandList" :key="index" @click="switchMenuCommand(index,item)">
                <img :src="item.imgUrl" alt="">
                <div class="menu_text" :class="[{'active':item.active},item.className]">{{item.name}}</div>
            </li>
        </ul>
        <ul class="bottom_menu" v-if="pageType=='agriculture'">
            <li class="menu_item" v-for="(item,index) in menuAgricultureList" :key="index" @click="switchMenuAgriculture(index,item)">
                <img :src="item.imgUrl" alt="">
                <div class="menu_text" :class="[{'active':item.active},item.className]">{{item.name}}</div>
            </li>
        </ul>

        <!-- 地图 -->
        <div id="mapBox"></div>

        <!-- 总览弹窗 -->
        <el-dialog
            :title="currentDialogList[currentDialogIndex]?currentDialogList[currentDialogIndex].name:''"
            :visible.sync="dialogCmp"
            :before-close="handleCmpClose">
            <div class="cmp_info_box">
                <div class="cmp_info_left">
                    <div class="cmp_basc cmp_item">
                        <div class="cmp_logo"><img src="../assets/images/cmp_logo.jpg" alt=""></div>
                        <div class="basc_left">
                            <div class="mgb-10">
                                <span class="info_label">统一信用代码：</span>
                                9137032377103388XY
                            </div>
                            <div>
                                <span class="info_label">企业地址：</span>
                                山东省淄博市沂源县鲁村镇鲁村
                            </div>
                        </div>
                        <div class="basc_right">
                            <div class="mgb-10">
                                <span class="info_label">成立日期：</span>
                                2005-01-27
                            </div>
                            <div>
                                <span class="info_label">联系电话：</span>
                                1234-12345678
                            </div>
                        </div>
                    </div>
                    <div class="cmp_user cmp_item">
                        <div class="user_item">
                            <div class="user_avatar">周</div>
                            <div>
                                <div class="mgb-10">
                                    <span class="info_label">法定代表人：</span>
                                    周*金
                                </div>
                                <div>
                                    <span class="info_label">身份证：</span>
                                    37************2333
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cmp_item">
                        <div class="mgb-10">证件信息</div>
                        <div class="cmp_cred">
                            <div class="cred_item">
                                <div class="cred_img">
                                    <img src="../assets/images/cmp_cred.png" alt="">
                                </div>
                                <div>营业执照</div>
                            </div>
                        </div>
                    </div>
                    <div class="cmp_plan cmp_item">
                        <div class="plan_item">
                            <div class="mgb-10">
                                <span class="info_label">企业年度计划：</span>
                                1000万元
                            </div>
                            <div>
                                <span class="info_label">等级利润：</span>
                                666.00万元
                            </div>
                        </div>
                        <div class="plan_item">
                            <div class="mgb-10">
                                <span class="info_label">登记收入：</span>
                                961.00万元
                            </div>
                            <div>
                                <span class="info_label">完成率：</span>
                                84.50%
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cmp_info_right">
                    <div class="cmp_item">
                        <div class="cmp_info_title mgb-10">建筑物信息</div>
                        <div class="build_img mgb-10"><img src="../assets/images/cmp_build.png" alt=""></div>
                        <div class="mgb-10">
                            <span class="info_label">所属区域：</span>
                            鲁村三村
                        </div>
                        <div class="mgb-10">
                            <span class="info_label">负责人：</span>
                            萧*博
                        </div>
                        <div class="mgb-10">
                            <span class="info_label">地址：</span>
                            淄博市沂源县鲁村镇泰薛路62号
                        </div>
                    </div>
                    <div class="cmp_item">
                        <div class="cmp_info_title mgb-10">企业房屋信息</div>
                        <div class="mgb-10">
                            <span class="info_label">房屋：</span>
                            淄博市沂源县鲁村镇泰薛路62号
                        </div>
                        <div class="mgb-10">
                            <span class="info_label">房主：</span>
                            李伟
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- 指挥调度 -->
        <el-dialog
            :title="commandList[1][currentCommandIndex]?commandList[1][currentCommandIndex].name+'监控':''"
            :visible.sync="dialogCommand"
            :before-close="handleCommandClose">
            <div class="command_box">
                <div class="video_box">
                    <video ref="video" width="320" height="240" controls>
                        <source :src="commandVideoUrl" type="video/mp4">
                        您的浏览器不支持 video 标签。
                    </video>
                </div>
                <div class="command_info"><span>设备编号：</span>202104030023</div>
            </div>
        </el-dialog>
        <el-dialog
            title="外来人员"
            :visible.sync="dialogCommand2"
            :before-close="handleCommandClose2">
            <div class="command_box">
                <div class="mgb-10">
                    <span class="font_green">姓 名：</span>{{commandList[0][currentCommandIndex].name}}
                </div>
                <div class="mgb-10">
                    <span class="font_green">身份证号：</span>37************2222
                </div>
                <div class="mgb-10">
                    <span class="font_green">联系电话：</span>188****1234
                </div>
                <div class="mgb-10">
                    <span class="font_green">登记时间：</span>2022-03-20 15:23:19
                </div>
            </div>
        </el-dialog>
        <!-- 文化旅游 -->
        <el-dialog
            :title="travelList[currentTravelIndex]?travelList[currentTravelIndex].name:''"
            :visible.sync="dialogTravel"
            :before-close="handleTravelClose">
            <div class="travel_box">
                <div class="travel_left">
                    <div class="topic_img mgb-10">
                        <img src="../assets/images/travel_img.png" alt="">
                    </div>
                    <div class="mgb-10">
                        <span class="font_green">举办时间：</span>
                        2021-04-03
                    </div>
                    <div class="mgb-10">
                        <span class="font_green">举办地点：</span>
                        山东沂源桃花岛景区
                    </div>
                </div>
                <div class="travel_right">
                    <div class="font_green mgb-10">简介</div>
                    <div class="mgb-10">
                        4月3日，山东沂源第二十七届汇泉桃花节在桃花岛景区开幕。
本届桃花节以“艺术点亮田园，文化振兴乡村”为主题，由沂源县委宣传部、县文化和旅游局、县融媒体中心、鲁村镇人民政府、山东财经大学乡村振兴学院主办，桃花岛艺术乡村文化旅游发展有限公司承办。设置了“水韵桃乡”、“悦音桃醉”、“翰墨桃韵”、“古意桃趣”、“艺术桃源”5个分会场，进一步丰富群众文化生活，呈现桃花岛崭新面貌，全面宣传推介乡村振兴。
近年来，沂源县依托生态、文化等资源优势，按照“以全域旅游为统领”的发展思路，解放思想、放大格局，链接高端、激活市场，努力将旅游资源优势转化为高质量发展胜势。举全县之力，精心策划实施了省级试点沂河源田园综合体项目，聚力推动一二三产业融合发展。
项目充分挖掘和利用沂河发源地良好生态、区位交通等优势，采取“公益基金+专业公司+合作社+农户”模式，集聚了保罗·安德鲁、福武总一郎等国际知名的专家团队，努力将现代艺术与原始生态、特色农业、乡土文化等融为一体，建成了刘玉堂文学馆、李怀杰艺术馆、李心田文学馆、时间之花等文学艺术场馆，揭牌成立了山东财经大学乡村振兴学院；同时，全面完善了各类基础设施，发展了一批优质产业项目，具备了做大做优做强的现实条件，将桃花岛景区建成了3A级旅游景区。
今后，沂源将继续竭尽全力，为景区开发、项目建设搞好服务，提供有力条件；同时，大力实施文旅节会品牌培塑工程，更高标准举办开展节会活动，实现以节促旅，以节兴旅，为加快建设智慧美丽活力幸福新沂源，努力打造产业生态开放道德新高地注入更多活力。
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- 生态振兴 -->
        <el-dialog
            :title="ecologyList[currentEcologyIndex]?ecologyList[currentEcologyIndex].name:''"
            :visible.sync="dialogEcology"
            :before-close="handleEcologyClose">
            <div class="travel_box">
                <div class="travel_left">
                    <div class="topic_img mgb-10">
                        <img src="../assets/images/travel_img.png" alt="">
                    </div>
                    <div class="mgb-10">
                        <span class="font_green">举办时间：</span>
                        2021-04-03
                    </div>
                    <div class="mgb-10">
                        <span class="font_green">举办地点：</span>
                        山东沂源桃花岛景区
                    </div>
                </div>
                <div class="travel_right">
                    <div class="font_green mgb-10">简介</div>
                    <div class="mgb-10">
                        4月3日，山东沂源第二十七届汇泉桃花节在桃花岛景区开幕。
本届桃花节以“艺术点亮田园，文化振兴乡村”为主题，由沂源县委宣传部、县文化和旅游局、县融媒体中心、鲁村镇人民政府、山东财经大学乡村振兴学院主办，桃花岛艺术乡村文化旅游发展有限公司承办。设置了“水韵桃乡”、“悦音桃醉”、“翰墨桃韵”、“古意桃趣”、“艺术桃源”5个分会场，进一步丰富群众文化生活，呈现桃花岛崭新面貌，全面宣传推介乡村振兴。
近年来，沂源县依托生态、文化等资源优势，按照“以全域旅游为统领”的发展思路，解放思想、放大格局，链接高端、激活市场，努力将旅游资源优势转化为高质量发展胜势。举全县之力，精心策划实施了省级试点沂河源田园综合体项目，聚力推动一二三产业融合发展。
项目充分挖掘和利用沂河发源地良好生态、区位交通等优势，采取“公益基金+专业公司+合作社+农户”模式，集聚了保罗·安德鲁、福武总一郎等国际知名的专家团队，努力将现代艺术与原始生态、特色农业、乡土文化等融为一体，建成了刘玉堂文学馆、李怀杰艺术馆、李心田文学馆、时间之花等文学艺术场馆，揭牌成立了山东财经大学乡村振兴学院；同时，全面完善了各类基础设施，发展了一批优质产业项目，具备了做大做优做强的现实条件，将桃花岛景区建成了3A级旅游景区。
今后，沂源将继续竭尽全力，为景区开发、项目建设搞好服务，提供有力条件；同时，大力实施文旅节会品牌培塑工程，更高标准举办开展节会活动，实现以节促旅，以节兴旅，为加快建设智慧美丽活力幸福新沂源，努力打造产业生态开放道德新高地注入更多活力。
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import AMap from 'AMap' // 引入高德地图
import gisHeader from '../components/gis-header.vue'
export default {
    components:{
        gisHeader
    },
    data(){
        return{
            pageType:'',
            map:{},
            mapImg:require('../assets/images/lucun_map.png'),
            menuList:[
                {
                    imgUrl:require('../assets/images/gis_icon_cmp.png'),
                    name:'企业',
                    className:'menu1',
                    active:false,
                },
                {
                    imgUrl:require('../assets/images/gis_icon_factory.png'),
                    name:'工厂',
                    className:'menu2',
                    active:false,
                },
                {
                    imgUrl:require('../assets/images/gis_icon_market.png'),
                    name:'超市',
                    className:'menu3',
                    active:false,
                },
                {
                    imgUrl:require('../assets/images/gis_icon_hospital.png'),
                    name:'卫生所',
                    className:'menu4',
                    active:false,
                },
                {
                    imgUrl:require('../assets/images/gis_icon_pharmacy.png'),
                    name:'药店',
                    className:'menu5',
                    active:false,
                },
                {
                    imgUrl:require('../assets/images/gis_icon_restaurant.png'),
                    name:'饭店',
                    className:'menu6',
                    active:false,
                },
                {
                    imgUrl:require('../assets/images/gis_icon_river.png'),
                    name:'河道',
                    className:'menu7',
                    active:false,
                },
            ],
            menuIndex:-1,
            treeData: [
                {
                    id:0,
                    label: '鲁村一村',
                    // children: [{
                    //     label: '二级 1-1',
                    //     children: [{
                    //         label: '三级 1-1-1'
                    //     }]
                    // }]
                }, 
                {
                    id:1,
                    label: '鲁村二村',
                    // children: [{
                    //     label: '二级 2-1',
                    //     children: [{
                    //     label: '三级 2-1-1'
                    //     }]
                    // }, {
                    //     label: '二级 2-2',
                    //     children: [{
                    //     label: '三级 2-2-1'
                    //     }]
                    // }]
                },
                {
                    id:2,
                    label: '鲁村三村',
                }, 
                {
                    id:3,
                    label: '鲁村四村',
                }, 
                {
                    id:4,
                    label: '鲁村五村',
                }, 
            ],
            treeIndex:0,
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            dateTime:'',
            areaAndWeather:'',
            dialogCmp:false,
            cmpList:[       //企业列表
                {
                    lng:118.063653,
                    lat:36.199908,
                    name:'淄博阳春三月乳业有限公司'
                },
                {
                    lng:118.048094,
                    lat:36.200498,
                    name:'淄博金东机械制造有限公司'
                },
                {
                    lng:118.046415,
                    lat:36.193553,
                    name:'晟源装饰'
                },
            ],
            cmpMarkList:[],       //企业标记点列表
            currentCmpIndex:0,      //当前点击的是第几个
            factoryList:[       //工厂列表
                {
                    lng:118.040187,
                    lat:36.199531,
                    name:'鲁源机械工程公司'
                },
                {
                    lng:118.04788,
                    lat:36.195099,
                    name:'博山蛟龙加工香肠'
                },
            ],
            factoryMarkList:[],       //工厂标记点列表
            currentFactoryIndex:0,      //当前点击的是第几个
            marketList:[       //超市列表
                {
                    lng:118.046462,
                    lat:36.197184,
                    name:'金源批发超市'
                },
                {
                    lng:118.044841,
                    lat:36.198357,
                    name:'隆福超市'
                },
                {
                    lng:118.049011,
                    lat:36.196836,
                    name:'乐万家超市'
                },
            ],
            marketMarkList:[],       //超市标记点列表
            currentMarketIndex:0,      //当前点击的是第几个
            hostList:[       //卫生所列表
                {
                    lng:118.048372,
                    lat:36.1963,
                    name:'沂源县中医院鲁村分院门诊部'
                },
                {
                    lng:118.048378,
                    lat:36.195751,
                    name:'鲁村中心卫生院'
                },
                {
                    lng:118.042824,
                    lat:36.199044,
                    name:'鲁村一村卫生室'
                },
            ],
            hostMarkList:[],       //卫生所标记点列表
            currentHostIndex:0,      //当前点击的是第几个
            pharmList:[       //药店列表
                {
                    lng:118.049525,
                    lat:36.196046,
                    name:'天和堂医药连锁药店(鲁村药店)'
                },
            ],
            pharmMarkList:[],       //药店标记点列表
            currentPharmIndex:0,      //当前点击的是第几个
            restrList:[       //饭店列表
                {
                    lng:118.052274,
                    lat:36.195552,
                    name:'好运来大众水饺'
                },
                {
                    lng:118.057084,
                    lat:36.193284,
                    name:'齐元军多美味饭庄'
                },
                {
                    lng:118.041132,
                    lat:36.1993,
                    name:'桃园大酒店'
                },
                {
                    lng:118.043437,
                    lat:36.198365,
                    name:'小二烤鱼'
                },
            ],
            restrMarkList:[],       //饭店标记点列表
            currentRestrIndex:0,      //当前点击的是第几个
            riverList:[       //河道列表
                {
                    lng:118.035202,
                    lat:36.185824,
                    name:'徐家庄河'
                },
            ],
            riverMarkList:[],       //河道标记点列表
            currentRiverIndex:0,      //当前点击的是第几个
            currentDialogList:[],
            currentDialogIndex:0,
            area1:[       //鲁村一村范围坐标点
                [118.044438,36.201217],
                [118.045695,36.201015],
                [118.04741,36.200787],
                [118.047519,36.199721],
                [118.047564,36.197291],
                [118.047497,36.197045],
                [118.04484,36.198027],
                [118.043615,36.198504]
            ],
            area2:[       //鲁村二村范围坐标点
                [118.04356,36.19853],
                [118.044393,36.201226],
                [118.043957,36.201306],
                [118.04244,36.201674],
                [118.040259,36.20205],
                [118.039637,36.201086],
                [118.040462,36.200909],
                [118.040044,36.199869],
                [118.041696,36.199212],
            ],
            mapArea1:[],
            mapArea2:[],
            area:[
                [
                    [118.044438,36.201217],
                    [118.045695,36.201015],
                    [118.04741,36.200787],
                    [118.047519,36.199721],
                    [118.047564,36.197291],
                    [118.047497,36.197045],
                    [118.04484,36.198027],
                    [118.043615,36.198504]
                ],
                [
                    [118.04356,36.19853],
                    [118.044393,36.201226],
                    [118.043957,36.201306],
                    [118.04244,36.201674],
                    [118.040259,36.20205],
                    [118.039637,36.201086],
                    [118.040462,36.200909],
                    [118.040044,36.199869],
                    [118.041696,36.199212],
                ],
                [
                    [118.047914 , 36.19705],
                    [118.048433 , 36.200267],
                    [118.04845 , 36.200538],
                    [118.050661 , 36.200186],
                    [118.053476 , 36.199848],
                    [118.053038 , 36.19754],
                    [118.052535 , 36.195364],
                    [118.049838 , 36.19627],
                    [118.048062 , 36.196905],
                ],
                [
                    [118.056822 , 36.196578],
                    [118.053081 , 36.197615],
                    [118.049913 , 36.198096],
                    [118.050625 , 36.200226],
                    [118.051956 , 36.200115],
                    [118.053356 , 36.202375],
                    [118.053907 , 36.202264],
                    [118.05393 , 36.202042],
                    [118.056019 , 36.201893],
                    [118.056294 , 36.201449],
                    [118.056707 , 36.201245],
                    [118.056474 , 36.199816],
                    [118.056595 , 36.199505],
                    [118.056936 , 36.199345],
                    [118.056995 , 36.199339],
                    [118.05696 , 36.197589],
                    [118.05691 , 36.196764],
                ],
                [
                    [118.056776 , 36.196511],
                    [118.054916 , 36.197011],
                    [118.052973 , 36.197345],
                    [118.052581 , 36.195343],
                    [118.05561 , 36.19426],
                    [118.056053 , 36.19412],
                    [118.057907 , 36.193431],
                    [118.05861 , 36.194445],
                    [118.058979 , 36.194431],
                    [ 118.059264 , 36.194986],
                    [118.058208 , 36.195648],
                    [118.058594 , 36.19604],
                    [118.057069 , 36.196405],
                    [118.057069 , 36.196432],
                ],
            ],
            mapArea:[
                [],
                [],
                [],
                [],
                [],
            ],
            // 重点项目
            projectList:[
                {
                    id:1,
                    name:'修建停车场',
                },
                {
                    id:2,
                    name:'修建垃圾回收处理站',
                },
            ],
            // 指挥调度-外来人口列表
            personList:[
                {
                    id:1,
                    name:'李雪',
                    time:'2022-03-20 10:23:00'
                },
                {
                    id:2,
                    name:'方轩豪',
                    time:'2022-03-20 10:23:00'
                },
                {
                    id:3,
                    name:'吴伟',
                    time:'2022-03-20 10:23:00'
                },
                {
                    id:4,
                    name:'吕恒彤',
                    time:'2022-03-20 10:23:00'
                },
                {
                    id:5,
                    name:'许俊强',
                    time:'2022-03-20 10:23:00'
                },
            ],
            menuCommandList:[       //底部导航按钮
                {
                    imgUrl:require('../assets/images/gis_icon_person.png'),
                    name:'人员',
                    className:'menu1',
                    active:false,
                },
                {
                    imgUrl:require('../assets/images/gis_icon_monitor.png'),
                    name:'监控',
                    className:'menu2',
                    active:false,
                },
            ],
            menuCommandIndex:0,
            menuAgricultureList:[       //底部导航按钮-数字农业
                {
                    imgUrl:require('../assets/images/gis_icon_till.png'),
                    name:'耕地',
                    className:'menu3',
                    active:false,
                },
                {
                    imgUrl:require('../assets/images/gis_icon_woodland.png'),
                    name:'林地',
                    className:'menu8',
                    active:false,
                },
                {
                    imgUrl:require('../assets/images/gis_icon_house.png'),
                    name:'宅基地',
                    className:'menu1',
                    active:false,
                },
                {
                    imgUrl:require('../assets/images/gis_icon_factory.png'),
                    name:'工业用地',
                    className:'menu2',
                    active:false,
                },
            ],
            menuAgricultureIndex:0,
            agricultureArea:[       //数字农业范围坐标点
                [
                    [118.037344 , 36.200284],
                    [118.036494 , 36.197428],
                    [118.036098 , 36.19736],
                    [118.035985 , 36.196948],
                    [118.035362 , 36.19704],
                    [118.035305 , 36.195692],
                    [118.036947 , 36.195554],
                    [118.038193 , 36.195646],
                    [118.038986 , 36.197976],
                    [118.041393 , 36.197565],
                    [118.041959 , 36.198662],
                    [118.03975 , 36.199484],
                ],
                [
                    [118.034909 , 36.195143],
                    [118.038108 , 36.1956],
                    [118.038844 , 36.197657],
                    [118.041364 , 36.197588],
                    [118.041959 , 36.198296],
                    [118.044394 , 36.197497],
                    [118.044762 , 36.196948],
                    [118.046064 , 36.194663],
                    [118.045895 , 36.194252],
                    [118.046234 , 36.193978],
                    [118.044875 , 36.192767],
                    [118.044252 , 36.188197],
                    [118.038476 , 36.18774],
                    [118.038731 , 36.188722],
                    [118.034682 , 36.189476],
                    [118.034201 , 36.190619],
                    [118.033606 , 36.190916],
                    [118.033805 , 36.192333],
                ],
                [
                    [118.039071 , 36.200101],
                    [118.039892 , 36.201586],
                    [118.044281 , 36.20097],
                    [118.047367 , 36.20065],
                    [118.052042 , 36.200106],
                    [118.052935 , 36.201155],
                    [118.053852 , 36.202307],
                    [118.054005 , 36.201998],
                    [118.056045 , 36.201793],
                    [118.056376 , 36.201361],
                    [118.056759 , 36.201258],
                    [118.056478 , 36.200661],
                    [118.056427 , 36.199941],
                    [118.056912 , 36.199427],
                    [118.056963 , 36.197534],
                    [118.056861 , 36.196753],
                    [118.055637 , 36.194284],
                    [118.047826 , 36.196999],
                ],
                [
                   [118.053369 , 36.194778], 
                   [118.052067 , 36.193681], 
                   [118.051302 , 36.193795], 
                   [118.050764 , 36.19199], 
                   [118.054445 , 36.191099], 
                   [118.056172 , 36.190322], 
                   [118.056993 , 36.190276], 
                   [118.05773 , 36.190847], 
                   [118.060504 , 36.190162], 
                   [118.061609 , 36.192013], 
                   [118.063081 , 36.194298], 
                   [118.064497 , 36.194252], 
                   [118.067158 , 36.193886], 
                   [118.069055 , 36.198114], 
                   [118.06948 , 36.20097], 
                   [118.065884 , 36.201586], 
                   [118.06393 , 36.198959], 
                   [118.063053 , 36.198639], 
                   [118.061467 , 36.1948], 
                   [118.059513 , 36.195257], 
                   [118.057843 , 36.193315], 
                ]
            ],
            agricultureMapArea:[
                [],
                [],
            ],
            commandList:[       //人员列表、监控列表
                [
                    {
                        lng:118.060053,
                        lat:36.195908,
                        name:'张磊',
                        type:'person'
                    },
                    {
                        lng:118.042094,
                        lat:36.200498,
                        name:'王晓明',
                        type:'person'
                    },
                    {
                        lng:118.046415,
                        lat:36.194553,
                        name:'吴旻',
                        type:'person'
                    },
                ],
                [
                    {
                        lng:118.05019,
                        lat:36.196128,
                        name:'主干道',
                        type:'monitor'
                    },
                    {
                        lng:118.047853,
                        lat:36.195728,
                        name:'兴隆路',
                        type:'monitor'
                    },
                ]
            ],
            commandMarkList:[
                [],     //标记的人员
                [],     //标记的监控
            ],       //指挥调度标记点列表
            currentCommandIndex:0,      //当前点击的是第几个
            dialogCommand:false,  //指挥调度-监控弹窗
            commandVideoUrl:require('../assets/images/command-video.mp4'),
            dialogCommand2:false, //指挥调度-人员弹窗

            // 文化旅游
            travelProjList:[
                {
                    id:1,
                    name:'春日踏青',
                    time:'2022-03-20'
                },
                {
                    id:2,
                    name:'丰收季果园采摘、农家乐',
                    time:'2022-03-20'
                },
                {
                    id:3,
                    name:'汇泉桃花艺术节',
                    time:'2022-03-20'
                },
            ],
            travelList:[       //文化旅游列表
                {
                    lng:118.057673,
                    lat:36.196937,
                    name:'春日踏青'
                },
                {
                    lng:118.049773,
                    lat:36.193898,
                    name:'丰收季果园采摘、农家乐'
                },
                {
                    lng:118.040628,
                    lat:36.195954,
                    name:'汇泉桃花艺术节'
                },
            ],
            travelIcon:require('../assets/images/gis_icon_travel.png'),
            travelMarkList:[],
            currentTravelIndex:0,
            dialogTravel:false,
            
            // 生态振兴
            ecologyProjList:[
                {
                    id:1,
                    name:'乡村绿化美化工程',
                    time:'2022-03-20'
                },
                {
                    id:2,
                    name:'林业特色产业',
                    time:'2022-03-20'
                },
                {
                    id:3,
                    name:'绿色乡村',
                    time:'2022-03-20'
                },
            ],
            ecologyList:[       //文化旅游列表
                {
                    lng:118.058834,
                    lat:36.199941,
                    name:'乡村绿化美化工程'
                },
                {
                    lng:118.056371,
                    lat:36.192858,
                    name:'林业特色产业'
                },
                {
                    lng:118.044535,
                    lat:36.192881,
                    name:'绿色乡村'
                },
            ],
            ecologyIcon:require('../assets/images/gis_icon_travel.png'),
            ecologyMarkList:[],
            currentEcologyIndex:0,
            dialogEcology:false,
        }
    },
    methods:{
        initMap(){
             var imageLayer = new AMap.ImageLayer({
                url: this.mapImg,
                bounds: new AMap.Bounds(
                    [118.029, 36.1855],
                    [118.070, 36.2065]
                ),
                zooms: [12, 20],
                opacity:1
            });
            this.map = new AMap.Map('mapBox', {
                center: [118.05, 36.196],
                zoom: 15.6,
                layers: [
                    AMap.createDefaultLayer(),
                    imageLayer,
                ]
            });
            // 绑定事件
            this.map.on('click', (ev)=>{
                console.log('当前点击的坐标是:',ev.lnglat.lng,',',ev.lnglat.lat)
            });

            
        },
        // 树形点击事件
        handleNodeClick(data){
            this.treeIndex = data.id;
            this.addArea()
        },
        // 企业弹窗关闭
        handleCmpClose(){
            this.dialogCmp = false;
        },
        // 指挥调度弹窗关闭
        handleCommandClose(){
            this.dialogCommand = false;
            this.$refs.video.pause();
        },
        handleCommandClose2(){
            this.dialogCommand2 = false;
        },
        // 文化旅游弹窗关闭
        handleTravelClose(){
            this.dialogTravel = false;
        },
        // 生态振兴弹窗关闭
        handleEcologyClose(){
            this.dialogEcology = false;
        },
        // 给地图添加点标记
        addMarkers(){
            // 创建 AMap.Icon 实例：
            var icon = new AMap.Icon({
                // 图标尺寸
                size: new AMap.Size(34, 34),
                // 图标的取图地址
                image: this.menuList[this.menuIndex].imgUrl,
                // 图标所用图片大小
                imageSize: new AMap.Size(34, 34),
            });

            let marks=[]
            if(this.menuIndex==0){
                //创建各个标记点 
                this.cmpList.forEach((item,i)=>{
                    this.cmpMarkList[i] = new AMap.Marker({
                        position: new AMap.LngLat(item.lng,item.lat),
                        title: item.name,
                        offset: new AMap.Pixel(-22,-17), // 设置点标记偏移量
                        icon: icon, // 添加 Icon 实例
                    });
                    this.cmpMarkList[i].on('click',()=>{
                        this.dialogCmp = true
                        this.currentCmpIndex = i
                        this.currentDialogList = this.cmpList
                        this.currentDialogIndex = i
                    })
                })
                marks = this.cmpMarkList
            }else if(this.menuIndex==1){
                this.factoryList.forEach((item,i)=>{
                    this.factoryMarkList[i] = new AMap.Marker({
                        position: new AMap.LngLat(item.lng,item.lat),
                        title: item.name,
                        offset: new AMap.Pixel(-22,-17), // 设置点标记偏移量
                        icon: icon, // 添加 Icon 实例
                    });
                    this.factoryMarkList[i].on('click',()=>{
                        this.dialogCmp = true
                        this.currentFactoryIndex = i
                        this.currentDialogList = this.factoryList
                        this.currentDialogIndex = i
                    })
                })
                marks = this.factoryMarkList
            }else if(this.menuIndex==2){
                this.marketList.forEach((item,i)=>{
                    this.marketMarkList[i] = new AMap.Marker({
                        position: new AMap.LngLat(item.lng,item.lat),
                        title: item.name,
                        offset: new AMap.Pixel(-22,-17), // 设置点标记偏移量
                        icon: icon, // 添加 Icon 实例
                    });
                    this.marketMarkList[i].on('click',()=>{
                        this.dialogCmp = true
                        this.currentMarketIndex = i
                        this.currentDialogList = this.marketList
                        this.currentDialogIndex = i
                    })
                })
                marks = this.marketMarkList
            }else if(this.menuIndex==3){
                this.hostList.forEach((item,i)=>{
                    this.hostMarkList[i] = new AMap.Marker({
                        position: new AMap.LngLat(item.lng,item.lat),
                        title: item.name,
                        offset: new AMap.Pixel(-22,-17), // 设置点标记偏移量
                        icon: icon, // 添加 Icon 实例
                    });
                    this.hostMarkList[i].on('click',()=>{
                        this.dialogCmp = true
                        this.currentHostIndex = i
                        this.currentDialogList = this.hostList
                        this.currentDialogIndex = i
                    })
                })
                marks = this.hostMarkList
            }else if(this.menuIndex==4){
                this.pharmList.forEach((item,i)=>{
                    this.pharmMarkList[i] = new AMap.Marker({
                        position: new AMap.LngLat(item.lng,item.lat),
                        title: item.name,
                        offset: new AMap.Pixel(-22,-17), // 设置点标记偏移量
                        icon: icon, // 添加 Icon 实例
                    });
                    this.pharmMarkList[i].on('click',()=>{
                        this.dialogCmp = true
                        this.currentPharmIndex = i
                        this.currentDialogList = this.pharmList
                        this.currentDialogIndex = i
                    })
                })
                marks = this.pharmMarkList
            }else if(this.menuIndex==5){
                this.restrList.forEach((item,i)=>{
                    this.restrMarkList[i] = new AMap.Marker({
                        position: new AMap.LngLat(item.lng,item.lat),
                        title: item.name,
                        offset: new AMap.Pixel(-22,-17), // 设置点标记偏移量
                        icon: icon, // 添加 Icon 实例
                    });
                    this.restrMarkList[i].on('click',()=>{
                        this.dialogCmp = true
                        this.currentRestrIndex = i
                        this.currentDialogList = this.restrList
                        this.currentDialogIndex = i
                    })
                })
                marks = this.restrMarkList
            }else if(this.menuIndex==6){
                this.riverList.forEach((item,i)=>{
                    this.riverMarkList[i] = new AMap.Marker({
                        position: new AMap.LngLat(item.lng,item.lat),
                        title: item.name,
                        offset: new AMap.Pixel(-22,-17), // 设置点标记偏移量
                        icon: icon, // 添加 Icon 实例
                    });
                    this.riverMarkList[i].on('click',()=>{
                        this.dialogCmp = true
                        this.currentRiverIndex = i
                        this.currentDialogList = this.riverList
                        this.currentDialogIndex = i
                    })
                })
                marks = this.riverMarkList
            }
            // 将创建的点标记添加到已有的地图实例：
            this.map.add(marks);
        },
        // 移除点标记
        removeMarks(){
            let marks=[]
            if(this.menuIndex==0){
                marks = this.cmpMarkList
            }else if(this.menuIndex==1){
                marks = this.factoryMarkList
            }else if(this.menuIndex==2){
                marks = this.marketMarkList
            }else if(this.menuIndex==3){
                marks = this.hostMarkList
            }else if(this.menuIndex==4){
                marks = this.pharmMarkList
            }else if(this.menuIndex==5){
                marks = this.restrMarkList
            }else if(this.menuIndex==6){
                marks = this.riverMarkList
            }
            // 将创建的点标记添加到已有的地图实例：
            this.map.remove(marks);
        },
        // 给地图添加点标记-指挥调度
        addMarkersCommand(){
            // 创建 AMap.Icon 实例：
            var icon = new AMap.Icon({
                // 图标尺寸
                size: new AMap.Size(34, 34),
                // 图标的取图地址
                image: this.menuCommandList[this.menuCommandIndex].imgUrl,
                // 图标所用图片大小
                imageSize: new AMap.Size(34, 34),
            });

            let marks=[]
            //创建各个标记点 
            this.commandList[this.menuCommandIndex].forEach((item,i)=>{
                this.commandMarkList[this.menuCommandIndex][i] = new AMap.Marker({
                    position: new AMap.LngLat(item.lng,item.lat),
                    title: item.name,
                    offset: new AMap.Pixel(-22,-17), // 设置点标记偏移量
                    icon: icon, // 添加 Icon 实例
                });
                this.commandMarkList[this.menuCommandIndex][i].on('click',()=>{
                    if(item.type=='person'){
                        this.dialogCommand2 = true
                    }else{
                        this.dialogCommand = true
                    }
                    this.currentCommandIndex = i
                })
            })
            marks = this.commandMarkList[this.menuCommandIndex]
            // 将创建的点标记添加到已有的地图实例：
            this.map.add(marks);
        },
        // 移除点标记-指挥调度
        removeMarksCommand(){
            // 将创建的点标记添加到已有的地图实例：
            this.map.remove(this.commandMarkList[this.menuCommandIndex]);
        },
        // 给地图添加点标记-文化旅游
        addMarkersTravel(){
            // 创建 AMap.Icon 实例：
            var icon = new AMap.Icon({
                // 图标尺寸
                size: new AMap.Size(34, 38),
                // 图标的取图地址
                image: this.travelIcon,
                // 图标所用图片大小
                imageSize: new AMap.Size(34, 38),
            });

            let marks=[]
            //创建各个标记点 
            this.travelList.forEach((item,i)=>{
                this.travelMarkList[i] = new AMap.Marker({
                    position: new AMap.LngLat(item.lng,item.lat),
                    title: item.name,
                    offset: new AMap.Pixel(-17,-19), // 设置点标记偏移量
                    icon: icon, // 添加 Icon 实例
                });
                this.travelMarkList[i].on('click',()=>{
                    this.dialogTravel = true
                    this.currentTravelIndex = i
                })
            })
            marks = this.travelMarkList
            // 将创建的点标记添加到已有的地图实例：
            this.map.add(marks);
        },
        // 给地图添加点标记-生态振兴
        addMarkersEcology(){
            // 创建 AMap.Icon 实例：
            var icon = new AMap.Icon({
                // 图标尺寸
                size: new AMap.Size(34, 38),
                // 图标的取图地址
                image: this.travelIcon,
                // 图标所用图片大小
                imageSize: new AMap.Size(34, 38),
            });

            let marks=[]
            //创建各个标记点 
            this.ecologyList.forEach((item,i)=>{
                this.ecologyMarkList[i] = new AMap.Marker({
                    position: new AMap.LngLat(item.lng,item.lat),
                    title: item.name,
                    offset: new AMap.Pixel(-17,-19), // 设置点标记偏移量
                    icon: icon, // 添加 Icon 实例
                });
                this.ecologyMarkList[i].on('click',()=>{
                    this.dialogEcology = true
                    this.currentEcologyIndex = i
                })
            })
            marks = this.ecologyMarkList
            // 将创建的点标记添加到已有的地图实例：
            this.map.add(marks);
        },
        // 切换底部按钮
        switchMenu(i,item){
            this.menuIndex = i;
            item.active = !item.active;
            if(item.active){
                this.addMarkers()
            }else{
                this.removeMarks()
            }
        },
        // 切换底部按钮-指挥调度
        switchMenuCommand(i,item){
            this.menuCommandIndex = i;
            item.active = !item.active;
            if(item.active){
                this.addMarkersCommand()
            }else{
                this.removeMarksCommand()
            }
        },
        // 绘制区域
        addArea(){
            // 一次性移除多条已创建的折线
            var polylines = [this.mapArea[0],this.mapArea[1],this.mapArea[2],this.mapArea[3],this.mapArea[4]];
            this.map.remove(polylines);
            // 多边形轮廓线的节点坐标数组
            var path = [];
            var colorList = [
                '#54F8D3',
                '#7E9BFF',
                '#F1C417',
                '#EE6B6B',
                '#60B8FF',
            ]
            var polygon
            this.area[this.treeIndex].forEach(item=>{
                path.push(new AMap.LngLat(item[0],item[1]))
            })
            this.mapArea[this.treeIndex] = polygon = new AMap.Polygon({
                path: path,  
                fillColor: colorList[this.treeIndex], // 多边形填充颜色
                borderWeight: 2, // 线条宽度，默认为 1
                strokeColor: colorList[this.treeIndex], // 线条颜色
            });
            this.map.add(polygon)
        },
        // 绘制区域-数字农业
        addAreaAgriculture(){
            // 一次性移除多条已创建的折线
            var polylines = [this.agricultureMapArea];
            this.map.remove(polylines);
            // 多边形轮廓线的节点坐标数组
            var path = [];
            var colorList = [
                '#F5A623',
                '#7FD22C',
                '#7E9BFF',
                '#F1C417',
            ]
            var polygon
            this.agricultureArea[this.menuAgricultureIndex].forEach(item=>{
                path.push(new AMap.LngLat(item[0],item[1]))
            })
            this.agricultureMapArea[this.menuAgricultureIndex] = polygon = new AMap.Polygon({
                path: path,  
                fillColor: colorList[this.menuAgricultureIndex], // 多边形填充颜色
                borderWeight: 2, // 线条宽度，默认为 1
                strokeColor: colorList[this.menuAgricultureIndex], // 线条颜色
            });
            this.map.add(polygon)
        },
        removeAreaAgriculture(){
            this.map.remove(this.agricultureMapArea[this.menuAgricultureIndex]);
        },
        // 切换底部按钮-数字农业
        switchMenuAgriculture(i,item){
            this.menuAgricultureIndex = i;
            item.active = !item.active;
            if(item.active){
                this.addAreaAgriculture()
            }else{
                this.removeAreaAgriculture()
            }
        },

    },
    mounted(){
        this.initMap();
        if(this.pageType=='travel'){
            this.addMarkersTravel();
        }else if(this.pageType=='ecology'){
            this.addMarkersEcology()
        }
    },
    created(){
        this.pageType = this.$route.query.type
    }
}
</script>